.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/********* CUSTOM  ******/
.ui.modal .content .description  {
  width: 100%;
}

.ui.modal .content .description .fluent-picture {
  float: left;
  margin-right: 1.5rem;
}

.ui.modal .content .description .fluent-picture.medium-size {
  max-width: 25%;
}

.scroll-modal-description-content {
  height: 100%;
  overflow: auto;
  padding-bottom: 5rem;
}

.ui.scrolling.modal,
.modals.dimmer .ui.scrolling.modal {
  /*margin-top: 5rem !important;*/
  margin: auto !important;
}

.modals.dimmer .ui.scrolling.modal .image.content {
  max-height:100%;overflow:auto;
}

.fixed-top {
  z-index: 1000;
}

.vbox-title {
  top: auto !important;
  bottom: 0px;
  width: 80%;
  margin: 20px 10%;
}

.hidden_intro {
  height: 8vh;
}

.hidden_intro .intro-content {
  display: none;
}